import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { Contact } from '../../../../models/contact.model';
import { ContactService } from '../../../../services/contact.service';

@Component({
  selector: 'contact-list-view-modal-component',
  templateUrl: './contact-list-view-modal.component.html',
  styleUrls: ['./contact-list-view-modal.component.scss'],
})
export class ContactListViewModalComponent extends AbstractComponent {

  contacts: Array<Contact> = [];

  constructor(
    injector: Injector,
    private dialogRef: MatDialogRef<ContactListViewModalComponent>,
    private contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) public data: Array<Contact>,
  ) {
    super(injector);
    this.contacts = data;
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }
}
