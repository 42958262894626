import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'card-percentage-chart-component',
  templateUrl: 'card-percentage-chart.component.html',
  styleUrls: ['card-percentage-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardPercentageChartComponent implements OnInit {

  @Input() percentage: number = 0;
  @Input() radius: number = 50;
  @Input() strokeWidth: number = 10;
  @Input() textColor: string;
  @Input() totalContacts: number = 0;
  @Input() datasetTitle: string;

  circumference: number = 0;
  dashOffset: number = 0;

  ngOnInit() {
    const normalizedRadius = this.radius - this.strokeWidth / 2;
    this.circumference = 2 * Math.PI * normalizedRadius;
    this.dashOffset = this.circumference * (1 - this.percentage / 100);
  }
}
