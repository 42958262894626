import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { debounceTime } from 'rxjs';
import { AbstractComponent } from '../../../commons/abstract.component';
import { Channel } from '../../../models/channel.model';
import { Department } from '../../../models/department.model';
import { AttendanceDistributionEnum } from '../../../models/enum/attendance-distribution.type';
import { SettingsAttendance } from '../../../models/settings-attendance.model';
import { DepartmentService } from '../../../services/department.service';
import { SettingsAttendanceService } from '../../../services/settings-attendance.service';

@Component({
  selector: 'settings-attendance-component',
  templateUrl: './settings-attendance.component.html',
  styleUrls: ['settings-attendance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsAttendanceComponent extends AbstractComponent implements OnInit, OnChanges {
  filterControlDepartmentToTransfer: FormControl = new FormControl('');
  filterControlDepartmentDefault: FormControl = new FormControl('');

  @Input() channel!: Channel;

  settingsAttendance: SettingsAttendance = {} as SettingsAttendance;

  listDepartmentToTransfer: Array<Department> = new Array<Department>();
  listDepartmentToDefault: Array<Department> = new Array<Department>();
  allDepartmens: Array<Department> = new Array<Department>();

  isInactive: boolean = false;

  isInactivityChatbot: boolean = false;

  constructor(
    injector: Injector,
    private service: SettingsAttendanceService,
    private departmentService: DepartmentService,
  ) {
    super(injector);
    this.createForm();
    this.loadDepartments();
    this.fetchFilterDepartment();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['channel']?.currentValue) {
      this.createForm();
      this.settingsAttendance = {} as SettingsAttendance;
      this.channel = changes['channel']?.currentValue;
      this.formGroup.patchValue({ channel: this.channel });
      this.getSettingsAttendance();
    }
  }

  getSettingsAttendance() {
    this.service.getByChannel(this.channel._id).subscribe({
      next: (value: SettingsAttendance) => {
        this.settingsAttendance = value;
        this.formGroup.patchValue(this.settingsAttendance);
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      minutesTransferAttendance: [1],
      tranferTo: [null],
      minutesFinishAttendance: [1],
      maxNumberAttendanceAttendant: [1],
      typeDistribution: [AttendanceDistributionEnum.LESS_ACTIVE_CONVERSATION, Validators.required],
      viewMessageBeforeAssume: [false],
      isAttendanceInactive: [false],
      closeAttendanceInactive: [false],
      limitAttendancePerAttendant: [false],
      sendResponseInactivy: [false],
      sendResponseAfterPeriod: [1],
      daySendResponse: [false],
      minutesSendResponse: [false],
      hourSendResponse: [false],
      autoAttendanceInactivy: [false],
      autoAfterPeriod: [1],
      departmentDefault: [null],
      periodToIgnoreAttendance: [1],
      checkedIgnoreAttendance: [false],
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.settingsAttendance, value);
    });
    if (!this.isAdmin()) {
      this.formGroup.disable();
    }
  }

  onConfirmClick(): void {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.service.update(this.settingsAttendance._id, this.settingsAttendance).subscribe({
      next: (value: SettingsAttendance) => {
        this.alertService.success('Configurações atualizadas com sucesso.');
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private loadDepartments() {
    this.departmentService.getList().subscribe({
      next: (value: Array<Department>) => {
        this.listDepartmentToTransfer = value;
        this.listDepartmentToDefault = value;
        this.allDepartmens = value;
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private fetchFilterDepartment(): void {
    this.filterControlDepartmentToTransfer.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('tranferTo')?.value || null;

      this.listDepartmentToTransfer = this.allDepartmens.filter(department =>
        department.name.toLowerCase().includes(value.toLowerCase()) &&
        department._id !== currentValue
      );

      const existingDepartment = this.allDepartmens.find(department => department._id === currentValue);
      if (existingDepartment) {
        this.listDepartmentToTransfer.push(existingDepartment);
      }
    });

    this.filterControlDepartmentDefault.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('departmentDefault')?.value || null;

      this.listDepartmentToDefault = this.allDepartmens.filter(department =>
        department.name.toLowerCase().includes(value.toLowerCase()) &&
        department._id !== currentValue
      );

      const existingDepartment = this.allDepartmens.find(department => department._id === currentValue);
      if (existingDepartment) {
        this.listDepartmentToDefault.push(existingDepartment);
      }
    });
  }

  onFocusTransferTo(): void {
    this.listDepartmentToTransfer = this.allDepartmens;
  }

  onFocusDepartmentDefault(): void {
    this.listDepartmentToDefault = this.allDepartmens;
  }

  onDistribuitionToggle(event: MatSlideToggleChange) {
    if (event.checked) {
      this.formGroup.get('typeDistribution').setValue(AttendanceDistributionEnum.LESS_ACTIVE_CONVERSATION);
    } else {
      this.formGroup.get('typeDistribution').setValue(AttendanceDistributionEnum.FIRST_ASSUME);
    }
  }

  onListaToggle(event: MatSlideToggleChange) {
    if (event.checked) {
      this.formGroup.get('typeDistribution').setValue(AttendanceDistributionEnum.FIRST_ASSUME);
    } else {
      this.formGroup.get('typeDistribution').setValue(AttendanceDistributionEnum.LESS_ACTIVE_CONVERSATION);
    }
  }

  changeIsAttendanceInactive(changedControl: string) {
    if (changedControl === 'autoAttendanceInactivy') {
      this.formGroup.get('isAttendanceInactive').setValue(false, { emitEvent: false });
    } else {
      this.formGroup.get('autoAttendanceInactivy').setValue(false, { emitEvent: false });
    }

    const isAttendanceInactive = this.formGroup.get('isAttendanceInactive').value;
    if (isAttendanceInactive) {
      this.formGroup.get('minutesTransferAttendance').setValidators([Validators.required]);
      this.formGroup.get('tranferTo').setValidators([Validators.required]);
    } else {
      this.formGroup.get('minutesTransferAttendance').clearValidators();
      this.formGroup.get('tranferTo').clearValidators();
    }

    this.formGroup.get('minutesTransferAttendance').updateValueAndValidity();
    this.formGroup.get('tranferTo').updateValueAndValidity();
  }

  changeCloseAttendanceInactive() {
    const closeAttendanceInactive = this.formGroup.get('closeAttendanceInactive').value();
    if (closeAttendanceInactive) {
      this.formGroup.get('minutesFinishAttendance').setValidators([Validators.required]);
    } else {
      this.formGroup.get('minutesFinishAttendance').clearValidators();
    }
    this.formGroup.get('minutesFinishAttendance').updateValueAndValidity();
  }

  changeLimitAttendancePerAttendant() {
    const limitAttendancePerAttendant = this.formGroup.get('limitAttendancePerAttendant').value();
    if (limitAttendancePerAttendant) {
      this.formGroup.get('maxNumberAttendanceAttendant').setValidators([Validators.required]);
    } else {
      this.formGroup.get('maxNumberAttendanceAttendant').clearValidators();
    }
    this.formGroup.get('maxNumberAttendanceAttendant').updateValueAndValidity();
  }

  changeSelectOptionsInactivy(event: any): void {

    this.formGroup.patchValue({
      daySendResponse: false,
      minutesSendResponse: false,
      hourSendResponse: false,
    });

    this.formGroup.patchValue({
      [event.value]: true,
    });
  }

  get selectedOptions(): string {
    if (this.formGroup.get('daySendResponse')?.value) {
      return 'daySendResponse';
    } else if (this.formGroup.get('hourSendResponse')?.value) {
      return 'hourSendResponse';
    } else if (this.formGroup.get('minutesSendResponse')?.value) {
      return 'minutesSendResponse';
    }
    return '';
  }
}
