<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Importar contatos</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Faça o upload do arquivo .csv com a base de contatos. Você pode
    baixar um Modelo para tal documento e ver as regras para preenchê-lo. <a style="color: blue; cursor: pointer"
      href="assets/csv/importacao_modelo_contato.csv" download="importacao_modelo_contato.csv">Clique aqui</a></h6>
  <form style="max-height: 465px; overflow-y: auto; overflow-x: hidden; padding: 5px;">
    <div class="flex w-full flex-col">
      <mat-tab-group animationDuration="200ms">
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="flex items-center gap-2">
              Importar contatos
            </span>
          </ng-template>
          <br />
          <div class="flex gap-2">
            <div class="flex flex-col flex-1">
              <label for="dropzone-file"
                class="flex flex-col items-center justify-center w-full h-42 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#E6E6FF" />
                    <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#F1F1FF" stroke-width="8" />
                    <g clip-path="url(#clip0_6074_436)">
                      <path
                        d="M32.5 32L28.5 28M28.5 28L24.5 32M28.5 28V37M36.89 34.39C37.8653 33.8583 38.6358 33.0169 39.0798 31.9986C39.5239 30.9804 39.6162 29.8432 39.3422 28.7667C39.0682 27.6901 38.4434 26.7355 37.5666 26.0534C36.6898 25.3714 35.6108 25.0007 34.5 25H33.24C32.9373 23.8292 32.3731 22.7423 31.5899 21.821C30.8067 20.8996 29.8248 20.1678 28.7181 19.6806C27.6113 19.1933 26.4085 18.9633 25.2001 19.0079C23.9916 19.0524 22.809 19.3703 21.7411 19.9376C20.6732 20.505 19.7479 21.3071 19.0346 22.2836C18.3213 23.26 17.8387 24.3855 17.6229 25.5754C17.4072 26.7652 17.4641 27.9885 17.7892 29.1532C18.1143 30.318 18.6992 31.3938 19.5 32.3"
                        stroke="#4213F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_6074_436">
                        <rect width="24" height="24" fill="white" transform="translate(16.5 16)" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                      class="font-semibold text-[#4213F6] text-xl">Clique aqui para carregar</span> ou arraste e solte
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">CSV</p>
                </div>
                <input id="dropzone-file" type="file" class="hidden" accept=".csv" (change)="handleFileSelect($event)"
                  #inputFile />
              </label>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span class="flex items-center gap-2">
              Regras
            </span>
          </ng-template>
          <br />
          <p>
            Seu csv deve possuir obrigatoriamente as seguintes regras abaixo para que você consiga realizar uma
            importação de contatos bem sucedida:
          </p>
          <br />
          <p>
            <b>1. Importante sobre colunas da planilha:</b>
          </p>
          <ul style="list-style-type: circle; padding-left: 20px;">
            <li>Seu csv deve possuir apenas uma folha.</li>
            <li>Essa folha deve possuir o texto <b>telefone</b> na célula A1, <b>nome</b> na célula B1, <b>email</b> na
              célula C1 e <b>etiquetas</b> na célula D1.
              Esses serão o cabeçalho da sua planilha.</li>
            <li>As dados das células devem ser separados por ;(ponto e vírgula)</li>
          </ul>
          <br />
          <p>
            <b>2. Importante sobre coluna do telefone:</b>
          </p>
          <ul style="list-style-type: circle; padding-left: 20px;">
            <li>Todos os telefones devem incluir o DDI, ou código do país, antes do número com DDD. O DDI do Brasil é
              55. (Exemplo: 5511988776655).</li>
            <li> Você não precisa formatar os números de telefone removendo pontos, espaços, vírgulas, traços ou
              qualquer outro caractere especial. Nós faremos isso automaticamente.</li>
            <li> Você não precisa se preocupar com a regra do nono dígito no número de telefone. Nós cuidaremos disso
              automaticamente.</li>
          </ul>
          <br />
          <p>
            <b>3. Importante sobre coluna das etiquetas:</b>
          </p>
          <ul style="list-style-type: circle; padding-left: 20px;">
            <li>O uso de etiquetas é opcional. Você pode importar apenas números e nomes.</li>
            <li>Para adicionar mais de 1 etiqueta para clientes, separe as etiquetas por vírgula (Ex: cliente, comprou,
              produtoX).</li>
            <li>O título da coluna deve ser 'etiquetas', não pode ser 'etiqueta'.</li>
          </ul>
          <br />
          <p>
            <b>4. Resumo:</b>
          </p>
          <ul style="list-style-type: circle; padding-left: 20px;">
            <li>Nomes das colunas deve ser 'telefone', 'nome', 'email' e 'etiquetas'.</li>
            <li>Telefones devem possuir o DDI antes do DDD. (Ex. 5511999999999).</li>
            <li>Etiquetas devem ser separadas por vírgulas e são opcionais.</li>
            <li>E-mail é opcional.</li>
            <li>Revise tudo antes de importar.</li>
          </ul>
          <p>
            Acesse o Modelo e apenas edite com suas informações. Lembre-se de apagar os textos de orientação e a imagem.
          </p>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="flex flex-col mt-4" *ngIf="csvContent.length">
      <div class="flex flex-row h-full justify-start items-start mt-2 gap-2">
        <div>
          <label class="text-sm text-gray-400">Total de Contatos:</label>
          <h5 class="font-semibold text-sm" style="text-align: center;">{{csvContent.length}}</h5>
        </div>
        <div>
          <label class="text-sm text-gray-400">Contatos sem erro:</label>
          <h5 class="font-semibold text-sm" style="text-align: center; color:darkgreen">{{csvContent.length -
            getNumberContactErrors()}}</h5>
        </div>
        <div>
          <label class="text-sm text-gray-400">Contatos com erro:</label>
          <h5 class="font-semibold text-sm" style="text-align: center; color: red">{{getNumberContactErrors()}}</h5>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-4" *ngIf="csvContent.length">
      <table class="min-w-full  divide-gray-100 divide-y-[3px]">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Telefone</th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Email</th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Etiquetas</th>
            <th scope="col" class="relative py-3.5 pl-3 pr-4">
              <span class="sr-only"></span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-gray-100 divide-y-[3px] bg-white">
          <tr *ngFor="let row of paginatedContent" class="rounded-none" role="row">
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
              [ngStyle]="{'color': row['hasError'] ? 'red' : 'black'}">{{ row['name'] }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
              [ngStyle]="{'color': row['hasError'] ? 'red' : 'black'}">{{ row['phone'] }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
              [ngStyle]="{'color': row['hasError'] ? 'red' : 'black'}">{{ row['email'] }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
              [ngStyle]="{'color': row['hasError'] ? 'red' : 'black'}">{{ row['tags'] }}</td>
            <td class="relative whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium">
              <div class="" role="none">
                <a href="javascript:void(0)"
                  class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                  role="menuitem" tabindex="-1" id="menu-item-0" (click)="delete(row)">
                  Remover
                </a>
              </div>
            </td>
          </tr>
          <tr class=""></tr>
        </tbody>
      </table>
      <mat-paginator [length]="csvContent.length" [pageSize]="pageSize" (page)="handlePage($event)">
      </mat-paginator>
    </div>
  </form>
</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="save()">
    {{ !loading ? 'Importar' : '' }}
    <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
  </button>
</div>