import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttendanceStatusEnum, Pager } from '../../public-api';
import { AbstractService } from '../commons/abstract.service';
import { Attendance, CreateAttendanceFromTemplate } from '../models/attendance.model';
interface SearchParams {
  [key: string]: string | number | object | undefined;
}

@Injectable({ providedIn: 'root' })
export class AttendanceService extends AbstractService<Attendance> {

  getURLBase(): string {
    return '/attendance';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Attendance): void {
    delete obj?._id;
  }

  preUpdate(obj: Attendance): void {
  }

  createFromTemplate(createAttendanceFromTemplate: CreateAttendanceFromTemplate): Observable<Attendance> {
    return super.post('/create', createAttendanceFromTemplate);
  }

  addRemoveTag(obj: { idTag: string, idAttendance: string }): Observable<Attendance> {
    return super.put(`/addRemoveTag/${obj.idAttendance}`, obj);
  }

  existsContactStatus(contactId: string, statusList: string[], channelId: string): Observable<Boolean> {
    let status = "";
    if (statusList && statusList.length > 0) {
      status = "&status=" + statusList.join("&status=");
    }
    return super.get(`/contacts/${contactId}/exists?v=1${status}&channelId=${channelId}`);
  }

  updateStatus(id: string, status: AttendanceStatusEnum): Observable<Attendance> {
    return super.get(`/update/status/${id}?status=${status}`);
  }

  assignUser(id: string, idUser: string): Observable<Attendance> {
    return super.get(`/assign/user/${id}?idUser=${idUser}`);
  }

  closeAttendance(idAttendance: string): Observable<Attendance> {
    return super.put(`/close/attendance/${idAttendance}`, { idAttendance });
  }

  updateMessagesStatus(attendanceId: string, read: boolean): Observable<void> {
    return super.put(`/up-att/${attendanceId}`, { read })
  }

  transferAttendance(attendanceId: string, idDepartment: string, idUser?: string): Observable<Attendance> {
    return super.put(`/transfer/attendance/${attendanceId}`, { idDepartment, idUser });
  }

  getCountAttendancesByContacts(idsContact: string[]): Observable<unknown> {
    return super.post(`/count/attendances/by/contacts`, { ids: idsContact });
  }

  getCountAttendancesByStatus(search?: string, params?: Partial<SearchParams>): Observable<Array<{ status: string, count: number }>> {
    const baseUrl: string = '/count/attendances/by/status';

    const getSearchConfig = (search: string): string => {
      if (!!search) {
        if (search.includes('+')) {
          const searches = search.split('+');
          return searches.map(s => `search=${s}`).join('&');
        } else {
          return `search=${search}`;
        }
      } else {
        return '';
      }
    }

    const searchQuery = getSearchConfig(search);
    let urling = `${baseUrl}${searchQuery ? '?' + searchQuery : ''}`;

    if (params) {
      for (let param in params) {
        urling = `${urling}${urling.includes('?') ? '&' : '?'}${param}=${params[param]}`;
      }
    }

    return super.get(urling);
  }

  updateCountUnreadMessages(idAttendance: string): Observable<void> {
    return super.get('/update/count/unread/messages/' + idAttendance);
  }

  getAttendanceHistory(page: number, perPage: number, search?: string, params?: Partial<SearchParams>): Observable<Pager<Attendance>> {
    const baseURL = `/pager/history`;
    const pagering = `${baseURL}?page=${page}&perPage=${perPage}`;

    const getSearchConfig = (search: string): string => {
      if (!!search) {
        if (search.includes('+')) {
          const searches = search.split('+');
          let urlingSerches = '';
          searches.forEach((search) => {
            urlingSerches += `&search=${search}`;
          });
          return urlingSerches;
        } else {
          return '&search=' + search;
        }
      } else {
        return '';
      }
    }
    const searching = `${pagering}${getSearchConfig(search)}`;

    let urling = `${searching}`;
    if (!!params) {
      for (let param in params) {
        urling = `${urling}&${param}=${params[param]}`;
      }
    }
    return super.get(urling);
  }

  activeUnactiveAI(attendanceId: string, actived: boolean): Observable<Attendance> {
    return super.put(`/activeUnactive/integration/AI/${attendanceId}`, { actived });
  }

  getPagerToAttendance(page: number, perPage: number, search?: string, params?: Partial<SearchParams>): Observable<Pager<Attendance>> {
    const baseURL = `/pager/attendance`;
    const pagering = `${baseURL}?page=${page}&perPage=${perPage}`;

    const getSearchConfig = (search: string): string => {
      if (!!search) {
        if (search.includes('+')) {
          const searches = search.split('+');
          let urlingSerches = '';
          searches.forEach((search) => {
            urlingSerches += `&search=${search}`;
          });
          return urlingSerches;
        } else {
          return '&search=' + search;
        }
      } else {
        return '';
      }
    }
    const searching = `${pagering}${getSearchConfig(search)}`;

    let urling = `${searching}`;
    if (!!params) {
      for (let param in params) {
        urling = `${urling}&${param}=${params[param]}`;
      }
    }
    return super.get(urling);
  }
}
