import { Location } from "@angular/common";
import { Injectable, Injector } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { UtilHelper } from "../helpers/util.helper";
import { Base } from "../models/base.model";
import { Company } from "../models/company.model";
import { Contact } from "../models/contact.model";
import { AttendanceStatusEnum } from "../models/enum/attendance.status.enum";
import { ChannelTypeEnum } from "../models/enum/channel.type";
import { RoleUserEnum } from "../models/enum/role.user.enum";
import { User } from "../models/user.model";
import { AlertService } from "../shared/components/alert/alert.service";
import { CONSTANTS, Constants, ENV, Environment } from "./injection.token";

@Injectable()
export abstract class AbstractComponent {

  public destroy$ = new Subject<void>();

  protected location: Location;
  protected router: Router;
  protected route: ActivatedRoute;
  protected formBuilder: FormBuilder;
  protected alertService: AlertService;
  protected constants: Constants;
  protected environment: Environment;
  protected subscriptions: Subscription = new Subscription();

  public searchString: string;
  public searchParams: any;
  public formGroup: FormGroup;
  public loading: boolean = false;
  public loadingContent: boolean = false;
  public isNew: boolean = false;

  public emailRegex: RegExp = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  public domainRegex: RegExp = /^([a-z0-9|-]+[a-z0-9]{1,}\.)*[a-z0-9|-]+[a-z0-9]{1,}\.[a-z]{2,}$/i;
  public dateRegex: RegExp = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i;
  public timeRegex: RegExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i;
  public phoneNumberRegex: RegExp = /^-?(0|[1-9]\d*)?$/;
  public domainHttpRegex: RegExp = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/i;

  public templateVariableList = [
    { value: '{{SAUDACAO}}', label: 'Saudação', icon: 'pan_tool' },
    { value: '{{NOME}}', label: 'Nome do Lead', icon: 'person' },
    { value: '{{EMPRESA}}', label: 'Empresa', icon: 'business' },
    { value: '{{PROTOCOLO}}', label: 'Número do Protocolo', icon: 'description' },
    { value: '{{LINK_CHANNEL}}', label: 'Link canal Whatsapp', icon: 'insert_link' }
  ];

  constructor(injector: Injector) {
    this.location = injector.get(Location);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.alertService = injector.get(AlertService);
    this.formBuilder = injector.get(FormBuilder);
    this.constants = injector.get(CONSTANTS);
    this.environment = injector.get(ENV);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get env() {
    return this.environment['env'];
  }

  getIDCurrentUser(): string {
    return this.getCurrentUserUser()._id;
  }

  getRoles(): Array<string> {
    return this.getCurrentUserUser().roles;
  }

  getNameCurrentUser(): string {
    return this.getCurrentUserUser().name;
  }

  getEmailCurrentUser(): string {
    return this.getCurrentUserUser().email;
  }

  getIDCurrentCompany(): string {
    return this.getCurrentUserUser().company._id;
  }

  getCurrentUserUser(): User {
    return JSON.parse(localStorage.getItem(this.constants['currentUser'])).user;
  }

  getIDDepartmentsUserLogged(): string[] {
    return this.getCurrentUserUser()?.['departments'];
  }

  getCompanyCurrentUser(): Company {
    return this.getCurrentUserUser().company;
  }

  getNameCompanyCurrentUser(): string {
    return this.getCurrentUserUser().company.name;
  }

  getCodeCompanyCurrentUser(): string {
    return this.getCurrentUserUser().company.code;
  }

  getSignatureConversation(): boolean {
    return this.getCurrentUserUser().signatureConversation ?? false;
  }

  getCurrentUser(): any {
    return JSON.parse(localStorage.getItem(this.constants['currentUser']));
  }

  getParam(key: string): any {
    let result = null;
    this.route.params.forEach((params: Params) => {
      result = params[key];
    });
    return result;
  }

  getQueryParam(key: string) {
    let result = null;
    this.route.queryParamMap.subscribe(queryParams => {
      result = queryParams.get(key);
    });
    return result;
  }

  isRoot() {
    const user = this.getCurrentUserUser();
    if (user) {
      return user.root ?? false;
    }
    return false;
  }

  isAdmin() {
    const roles = this.getRoles();
    if (!!roles && roles.length > 0) {
      return roles.length === 3
        && roles.includes(RoleUserEnum.ADMIN)
        && roles.includes(RoleUserEnum.SUPERVISOR)
        && roles.includes(RoleUserEnum.ATTENDANT);
    }
    return false;
  }

  isSupervisor(): boolean {
    const roles = this.getRoles();
    if (!!roles && roles.length > 0) {
      return roles.length === 2
        && roles.includes(RoleUserEnum.SUPERVISOR)
        && roles.includes(RoleUserEnum.ATTENDANT);
    }
    return false;
  }

  isAttendant(): boolean {
    const roles = this.getRoles();
    if (!!roles && roles.length > 0) {
      return roles.length === 1
        && roles.includes(RoleUserEnum.ATTENDANT);
    }
    return false;
  }

  goBack(): void {
    this.location.back();
  }

  reset() {
    this.formGroup.reset();
  }

  isFieldValid(field: string, formGroup?: FormGroup) {
    if (!formGroup) {
      return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
    } else {
      return !formGroup.get(field).valid && formGroup.get(field).touched;
    }
  }

  displayFieldCss(field: string, formGroup?: FormGroup) {
    return {
      'is-invalid': (formGroup ? this.isFieldValid(field, formGroup) : this.isFieldValid(field)),
      // 'is-valid': (formGroup ? this.isFieldValid(field, formGroup) : this.isFieldValid(field))
    };
  }

  isFieldInvalidAndDirtyAndTouched(field: string, formGroup?: FormGroup) {
    if (!formGroup) {
      return this.formGroup.get(field).invalid && (this.formGroup.get(field).dirty || this.formGroup.get(field).touched);
    } else {
      return formGroup.get(field).invalid && (formGroup.get(field).dirty || formGroup.get(field).touched);
    }
  }

  isFieldErrosRequired(field: string, formGroup?: FormGroup) {
    if (!formGroup) {
      return this.formGroup.get(field).errors['required'];
    } else {
      return formGroup.get(field).errors['required'];
    }
  }

  isFieldErrosPattern(field: string, formGroup?: FormGroup) {
    if (!formGroup) {
      return this.formGroup.get(field).errors['pattern'];
    } else {
      return formGroup.get(field).errors['pattern'];
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(formGroup => {
          Object.keys(formGroup.value).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
              control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
              this.validateAllFormFields(control);
            }
          });
        });
      }
    });
  }

  public getUserImage(user: User) {
    return UtilHelper.getUserImage(user);
  }

  public getContactImage(contact: Contact) {
    return UtilHelper.getContactImage(contact);
  }

  public onImageError(event: Event): void {
    return UtilHelper.onImageError(event);
  }

  public getCompanyImage(company: Company) {
    return UtilHelper.getCompanyImage(company);
  }

  public getTranslatedTypeChannel(type: ChannelTypeEnum) {
    const types = {
      [ChannelTypeEnum.CLOUD_API]: 'Cloud API',
      [ChannelTypeEnum.EVOLUTION_API]: 'Business',
      [ChannelTypeEnum.EVOLUTION_GO_API]: 'Business PRO',
    };
    return types[type];
  }

  public getTranslatedStatusAttendance(status: AttendanceStatusEnum) {
    const statuses = {
      [AttendanceStatusEnum.OVERTIME]: 'Fora do horário',
      [AttendanceStatusEnum.PENDING]: 'Pendente',
      [AttendanceStatusEnum.IN_PROGRESS]: 'Em andamento',
      [AttendanceStatusEnum.PAUSED]: 'Pausado',
      [AttendanceStatusEnum.CLOSED]: 'Encerrado',
    };
    return statuses[status];
  }


  public compareWithObjectBase(value1: Base, value2: Base): boolean {
    return value1._id === value2._id;
  }

}