<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Contatos</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">
    Abaixo está os contatos enviados pelo cliente
  </h6>

  <div class="container-page mt-[15px]">
    <div id="table-modal-response" *ngIf="contacts.length > 0">
      <table class="min-w-full divide-gray-100 divide-y-[3px]">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Telefone</th>
          </tr>
        </thead>
        <tbody class="divide-gray-100 divide-y-[3px] bg-white">
          @for (item of contacts; track $index) {
          <tr class="rounded-none">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{item.name | truncate: 50 }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">{{item.phone}}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>
    <empty-records-component *ngIf="contacts?.length == 0" [message]="'Você ainda não tem contatos adicionados'"
      [subMessage]="'Adicione um contato ao lado.'">
    </empty-records-component>
  </div>

</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[100%]"
    (click)="close()">Fechar</button>
</div>