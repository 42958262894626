<svg [attr.height]="100" [attr.width]="radius * 2" [attr.viewBox]="'0 0 ' + radius * 2 + ' ' + radius * 2">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stop-color="#4213f6" />
      <stop offset="100%" stop-color="#dcd6f7" />
    </linearGradient>
  </defs>

  <circle [attr.cx]="radius" [attr.cy]="radius" [attr.r]="radius - strokeWidth / 2" [attr.stroke-width]="strokeWidth"
    stroke="#fff" fill="none"></circle>

  <circle [attr.cx]="radius" [attr.cy]="radius" [attr.r]="radius - strokeWidth / 2" [attr.stroke-width]="strokeWidth"
    stroke="url(#gradient)" [attr.stroke-dasharray]="circumference" [attr.stroke-dashoffset]="dashOffset" fill="none"
    stroke-linecap="round" [ngStyle]="{ transform: 'rotate(120deg)', transformOrigin: '50% 50%' }"></circle>

  <text x="50%" y="50%" text-anchor="middle" dy=".3em" [attr.font-size]="radius / 3" font-family="Arial, sans-serif"
    [attr.fill]="textColor">
    {{ percentage | number: '1.0-1' }}%
  </text>
</svg>